import React, { useState } from "react";

import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { message } from "antd";

import { assignTimeBlocks } from "~/utils/api/v2/timeblocks";
import { daysToSpanish } from "~/utils/dates/names";
import { Sector } from "~/utils/interfaces/Places";
import { MedicalAction, MedicalService } from "~/utils/interfaces/Services";
import { TimeBlock } from "~/utils/interfaces/Timeblock";

interface Props {
  onCancel: () => void;
  timeblocks: TimeBlock[];
  currentTimeblocks: TimeBlock[];
  updateTimeblocks?: (newTimeblocks: TimeBlock[]) => void;
  refreshTimeblocks?: () => void;
  nurseId: string;
  sectors: Sector[];
  medicalActions: MedicalAction[];
  medicalServices: MedicalService[];
}

function AddTimeblock({
  timeblocks,
  currentTimeblocks,
  refreshTimeblocks,
  nurseId,
  sectors,
  medicalActions,
  medicalServices,
  onCancel,
}: Props): JSX.Element {
  const [selectedDay, setSelectedDay] = useState<string>("monday");
  const [selectedBeginHour, setSelectedBeginHour] = useState<string>(timeblocks[0].begin_hour);
  const [selectedEndHour, setSelectedEndHour] = useState<string>(timeblocks[0].end_hour);
  const [selectedSectors, setSelectedSectors] = useState<string[]>(["all"]);
  const [selectedMedicalActions, setSelectedMedicalActions] = useState<string[]>(["all"]);
  const [selectedMedicalServices, setSelectedMedicalServices] = useState<string[]>(["all"]);
  const [loading, setLoading] = useState<boolean>(false);

  const selectedTimeBlocksIds = (): Array<string> => {
    return timeblocks.reduce((reducer: string[], timeblock: TimeBlock) => {
      if (timeblock.day === selectedDay) {
        if (timeblock.begin_hour >= selectedBeginHour && timeblock.end_hour <= selectedEndHour) {
          reducer.push(timeblock.id);
        }
      }
      return reducer;
    }, []);
  };

  const handleMultiSelectChange = (
    event: React.ChangeEvent<{ value: unknown }>,
    currentValue: string[],
    setter: (value: string[]) => void,
  ) => {
    const value = event.target.value as string[];
    if (value.includes("all") && currentValue.length > 1) {
      setter(["all"]);
    } else if (value.length > 1 && value.includes("all")) {
      setter(value.filter((v) => v !== "all"));
    } else {
      setter(value);
    }
  };

  const handleSectorChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    handleMultiSelectChange(event, selectedSectors, setSelectedSectors);
  };

  const handleMedicalActionChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    handleMultiSelectChange(event, selectedMedicalActions, setSelectedMedicalActions);
  };

  const handleMedicalServiceChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    handleMultiSelectChange(event, selectedMedicalServices, setSelectedMedicalServices);
  };

  const handleAcceptButton = async (): Promise<void> => {
    setLoading(true);
    try {
      const selectedIds = selectedTimeBlocksIds();
      const inUseTimeblocks = Object.values(currentTimeblocks).flatMap((x) => {
        if (x.length > 0) {
          return x.flatMap((y) => {
            return y.flatMap((i) => i.id);
          });
        }
      });
      const intersection = inUseTimeblocks.filter((x) => selectedIds.includes(x));
      if (intersection.length > 0) {
        const anIntersection = timeblocks.filter((x) => x.id == intersection[0])[0];
        throw {
          message: `El bloque ${daysToSpanish[anIntersection.day]} de ${anIntersection.begin_hour}-${
            anIntersection.end_hour
          } ya esta en uso`,
        };
      }

      const res = await assignTimeBlocks({
        nurse_id: nurseId,
        timeblocks_ids: selectedIds,
        sectors_ids: selectedSectors.includes("all") ? [] : selectedSectors,
        medical_action_ids: selectedMedicalActions.includes("all") ? [] : selectedMedicalActions,
        medical_service_ids: selectedMedicalServices.includes("all") ? [] : selectedMedicalServices,
      });
      if (res.error) {
        message.error("Error al agregar timeblocks");
      } else {
        message.success("Timeblocks agregados");
        refreshTimeblocks?.();
      }
    } catch (err) {
      message.error("Error al agregar timeblocks");
    }
    setLoading(false);
  };

  const orderedMedicalServices = medicalServices.sort((a, b) => a.name.localeCompare(b.name));
  const orderedMedicalActions = medicalActions.sort((a, b) => a.name.localeCompare(b.name));
  return (
    <div className="m-2">
      <h4>Agregar disponibilidad</h4>
      <div className="flex flex-row gap-x-8 my-4">
        <FormControl>
          <InputLabel>Día</InputLabel>
          <Select
            value={selectedDay}
            onChange={(e) => {
              setSelectedDay(e.target.value as string);
            }}
          >
            <MenuItem value="monday">Lunes</MenuItem>
            <MenuItem value="tuesday">Martes</MenuItem>
            <MenuItem value="wednesday">Miércoles</MenuItem>
            <MenuItem value="thursday">Jueves</MenuItem>
            <MenuItem value="friday">Viernes</MenuItem>
            <MenuItem value="saturday">Sábado</MenuItem>
            <MenuItem value="sunday">Domingo</MenuItem>
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel>Inicio</InputLabel>
          <Select
            value={selectedBeginHour}
            onChange={(e) => {
              setSelectedBeginHour(e.target.value as string);
            }}
          >
            {timeblocks.map((timeblock: TimeBlock) => {
              // assume all days have the same possible timeblocks available
              if (timeblock.day === "monday") {
                return (
                  <MenuItem
                    key={timeblock.begin_hour}
                    value={timeblock.begin_hour}
                  >
                    {timeblock.begin_hour}
                  </MenuItem>
                );
              }
            })}
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel>Término</InputLabel>
          <Select
            value={selectedEndHour}
            onChange={(e) => {
              setSelectedEndHour(e.target.value as string);
            }}
          >
            {timeblocks.map((timeblock: TimeBlock) => {
              // assume all days have the same possible timeblocks available
              if (timeblock.day === "monday") {
                return (
                  <MenuItem
                    key={timeblock.end_hour}
                    value={timeblock.end_hour}
                  >
                    {timeblock.end_hour}
                  </MenuItem>
                );
              }
            })}
          </Select>
        </FormControl>
        <FormControl className="w-1/3">
          <InputLabel>Sectores</InputLabel>
          <Select
            multiple
            value={selectedSectors}
            onChange={handleSectorChange}
          >
            <MenuItem value="all">Todos</MenuItem>
            {sectors.map((sector) => (
              <MenuItem
                key={sector.id}
                value={sector.id}
              >
                {sector.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className="flex flex-row gap-x-8 my-4">
        <FormControl className="w-1/3">
          <InputLabel>Medical Actions Preferred</InputLabel>
          <Select
            multiple
            value={selectedMedicalActions}
            onChange={handleMedicalActionChange}
          >
            <MenuItem value="all">Todos</MenuItem>
            {orderedMedicalActions.map((medicalAction) => (
              <MenuItem
                key={medicalAction.id}
                value={medicalAction.id}
              >
                {medicalAction.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl className="w-1/3">
          <InputLabel>Medical Services Preferred</InputLabel>
          <Select
            multiple
            value={selectedMedicalServices}
            onChange={handleMedicalServiceChange}
          >
            <MenuItem value="all">Todos</MenuItem>
            {orderedMedicalServices.map((medicalService) => (
              <MenuItem
                key={medicalService.id}
                value={medicalService.id}
              >
                {medicalService.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className="flex flex-row gap-x-4 my-2">
        <Button
          variant="outlined"
          color="primary"
          onClick={handleAcceptButton}
          disabled={loading}
        >
          Agregar
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={onCancel}
          disabled={loading}
        >
          Cancelar
        </Button>
      </div>
    </div>
  );
}

export default AddTimeblock;
