import React from "react";

import PrivateRoute from "~/components/Authentication/PrivateRoute";
import NurseSchedule from "~/components/Nurses/NurseSchedule";

type ScheduleProps = {
  id: string;
};

function Schedule({ id: nurseId }: ScheduleProps) {
  return (
    <PrivateRoute>
      <NurseSchedule nurseId={nurseId} />
    </PrivateRoute>
  );
}

export default Schedule;
